//
// Jumbotron
// --------------------------------------------------

.jumbotron {
    padding-top: $jumbotron-padding;
    padding-bottom: $jumbotron-padding;
    margin-bottom: $jumbotron-padding;
    color: $jumbotron-color;
    background-color: $jumbotron-bg;

    h1,
    .h1 {
        color: $jumbotron-heading-color;
    }

    p {
        margin-bottom: calc($jumbotron-padding / 2);
        font-size: $jumbotron-font-size;
        font-weight: 200;
    }

    > hr {
        border-top-color: darken($jumbotron-bg, 10%);
    }

    .container &,
    .container-fluid & {
        border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
        padding-left: calc($grid-gutter-width / 2);
        padding-right: calc($grid-gutter-width / 2);
    }

    .container {
        max-width: 100%;
    }

    @media screen and (min-width: $screen-sm-min) {
        padding-top: ($jumbotron-padding * 1.6);
        padding-bottom: ($jumbotron-padding * 1.6);

        .container &,
        .container-fluid & {
            padding-left: ($jumbotron-padding * 2);
            padding-right: ($jumbotron-padding * 2);
        }

        h1,
        .h1 {
            font-size: $jumbotron-heading-font-size;
        }
    }
}
